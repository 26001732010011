import pluralize from "pluralize";

const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

const plurarizeStr = str => {
    return pluralize.plural(str);
}

const jsUcfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const formatPropType = str => {
    return jsUcfirst(plurarizeStr(camelizedString(str)))
}

export const formatIndianCurrency = function (amount) {
    if (amount >= 10000000) {
        let crore = amount / 10000000;
        return `${crore % 1 !== 0 ? crore.toFixed(2).replace('.00', '') : crore}Cr`;
    } else if (amount >= 100000) {
        let lakh = amount / 100000;
        return `${lakh % 1 !== 0 ? lakh.toFixed(2).replace('.00', '') : lakh}L`;
    } else if (amount >= 1000) {
        let thousand = amount / 1000;
        return `${thousand % 1 !== 0 ? thousand.toFixed(2).replace('.00', '') : thousand}K`;
    } else {
        return `${Math.round(amount)}`;
    }
};

export { formatPropType };