import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';
// config file

const MatchedProperties = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const location = useLocation();
  const { propertyID } = location.state || {};

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Matched Properties");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
   
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue:
          "createdByName=createdByName=createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "officeName",
        fieldName: "officeName",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Office Name",
        derivedValue: "officeName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "left",
        show: true,
        field: "officeObjId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        stringType: null,
        header: "Browse Photos",
        derivedValue: "browsePhotos=browsePhotos=browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 8,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "250px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        header: "Title",
        derivedValue: "title=city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "180px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        header: "Property For",
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr", color: 'primary' },
          { label: "Rent", value: "Rent", id: "msr", color: 'secondary' },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyFor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Type",
        derivedValue: "propertyType=propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr", color: "primary" },
          { label: "Flat", value: "Flat", id: "msr", color: "secondary" },
          { label: "Duplex", value: "Duplex", id: "msr", color: "warning" },
          { label: "Bungalow", value: "Banglow", id: "msr", color: "success" },
          { label: "Commercial", value: "Commercial", id: "msr", color: "danger" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr", color: "primary" },
          { label: "Flat", value: "Flat", id: "msr", color: "secondary" },
          { label: "Duplex", value: "Duplex", id: "msr", color: "warning" },
          { label: "Bungalow", value: "Banglow", id: "msr", color: "success" },
          { label: "Commercial", value: "Commercial", id: "msr", color: "danger" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "180px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Price / Rent",
        derivedValue: "price=price=price=price=price=price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 100000)`
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Rent",
        derivedValue: "rent=rent=rent=rent=rent=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "rent",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposite",
        value: "",
        label: "Deposite",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Deposite",
        derivedValue: "deposite=deposite=deposite=deposite=deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "deposite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 1000)`
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 5,
        stringType: null,
        header: "City",
        derivedValue: "city=city=city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Landmark",
        derivedValue: "landmark=landmark=landmark=landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "landmark",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "160px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        header: "Plot Area",
        derivedValue: "plotArea=plotArea=plotArea=plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "plotArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'attachField',
        attachField: 'areaUnits',
        filterPlaceholder: `Enter area(e.g., 1000)`
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "160px",
        addFormOrder: 13,
        editFormOrder: 12,
        stringType: null,
        header: "Carpet Area",
        derivedValue: "carpetArea=carpetArea=carpetArea=carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "carpetArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'attachField',
        attachField: 'areaUnits',
        filterPlaceholder: `Enter area(e.g., 1000)`
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "160px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Built Up Area",
        derivedValue:
          "builtUpArea=builtUpArea=builtUpArea=builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "builtUpArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'attachField',
        attachField: 'areaUnits',
        filterPlaceholder: `Enter area(e.g., 1000)`
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "number",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "170px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        header: "Floor Number",
        derivedValue:
          "floorNumber=floorNumber=floorNumber=floorNumber=floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "180px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        header: "Number Of Floors",
        derivedValue:
          "numberOfFloors=numberOfFloors=numberOfFloors=numberOfFloors=numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "150px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Availability",
        derivedValue:
          "availability=availability=availability=availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "190px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        header: "Furnishing Type",
        derivedValue:
          "furnishingType=furnishingType=furnishingType=furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "furnishingType",
        showOrHideFields: [],
        label0: "Un Furnished",
        value0: "Un Furnished",
        label1: "Semi Furnished",
        value1: "Semi Furnished",
        label2: "Furnished",
        value2: "Furnished",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "textarea",
        placeholder: "Amenties",
        value: "",
        label: "Amenties",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        header: "Amenties",
        derivedValue: "amenties=amenties=amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        header: "Description",
        derivedValue:
          "propertyDescription=propertyDescription=propertyDescription=propertyDescription=propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "propertyDescription",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        header: "Client",
        derivedValue: "client=client=client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "left",
        show: true,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Clients_firstName",
        type: "text",
        placeholder: "Clients_firstName",
        label: "Clients_firstName",
        header: "Clients firstName",
        width: 110,
        parentField: "client",
        id: "Clients_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: false,
        field: "Clients_firstName",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "Monthly Maintenance",
        value: "",
        label: "Monthly Maintenance",
        width: "210px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Monthly Maintenance",
        derivedValue:
          "monthlyMaintenance=monthlyMaintenance=monthlyMaintenance=monthlyMaintenance=monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "monthlyMaintenance",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 1000)`
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "BedRooms",
        derivedValue: "bedRooms=bedRooms=bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "bedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        header: "BathRooms",
        derivedValue: "bathRooms=bathRooms=bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "bathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "text",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Status",
        derivedValue:
          "propertyStatus=propertyStatus=propertyStatus=propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        header: "Updated By",
        derivedValue:
          "updatedByName=updatedByName=updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "expiringSoon",
        fieldName: "expiringSoon",
        type: "text",
        placeholder: "Expiring Soon",
        value: "",
        label: "Expiring Soon",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Expiring Soon",
        derivedValue: "expiringSoon=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "expiringSoon",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "expiringSoon",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    
    return [
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        derivedValue: "title=city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "propertyType=propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "price=price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        derivedValue: "rent=rent=rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposite",
        value: "",
        label: "Deposite",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        derivedValue: "deposite=deposite=deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 12,
        stringType: null,
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "number",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        derivedValue: "floorNumber=floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        derivedValue: "numberOfFloors=numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "Un Furnished",
        value0: "Un Furnished",
        label1: "Semi Furnished",
        value1: "Semi Furnished",
        label2: "Furnished",
        value2: "Furnished",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "textarea",
        placeholder: "Amenties",
        value: "",
        label: "Amenties",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        derivedValue:
          "propertyDescription=propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        stringType: null,
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: false,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "Monthly Maintenance",
        value: "",
        label: "Monthly Maintenance",
        width: "180px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue:
          "monthlyMaintenance=monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "text",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        derivedValue: "propertyStatus=propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        header: "Title",
        derivedValue: "title=city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        header: "Property For",
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "propertyFor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Type",
        derivedValue: "propertyType=propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Price",
        derivedValue: "price=price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Rent",
        derivedValue: "rent=rent=rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "rent",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposite",
        value: "",
        label: "Deposite",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Deposite",
        derivedValue: "deposite=deposite=deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "deposite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 5,
        stringType: null,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Landmark",
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "landmark",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        header: "Plot Area",
        derivedValue: "plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "plotArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 12,
        stringType: null,
        header: "Carpet Area",
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "carpetArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Built Up Area",
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "builtUpArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "number",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        header: "Floor Number",
        derivedValue: "floorNumber=floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        header: "Number Of Floors",
        derivedValue: "numberOfFloors=numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Availability",
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        header: "Furnishing Type",
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "furnishingType",
        showOrHideFields: [],
        label0: "Un Furnished",
        value0: "Un Furnished",
        label1: "Semi Furnished",
        value1: "Semi Furnished",
        label2: "Furnished",
        value2: "Furnished",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "textarea",
        placeholder: "Amenties",
        value: "",
        label: "Amenties",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        header: "Amenties",
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        header: "Description",
        derivedValue:
          "propertyDescription=propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "propertyDescription",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        stringType: null,
        header: "Browse Photos",
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: false,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        header: "Client",
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "center",
        show: false,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Clients_firstName",
        type: "text",
        placeholder: "Clients_firstName",
        label: "Clients_firstName",
        header: "Clients firstName",
        width: 110,
        parentField: "client",
        id: "Clients_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: true,
        field: "Clients_firstName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "Monthly Maintenance",
        value: "",
        label: "Monthly Maintenance",
        width: "180px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Monthly Maintenance",
        derivedValue:
          "monthlyMaintenance=monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "monthlyMaintenance",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "Bed Rooms",
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        header: "Bath Rooms",
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "text",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Status",
        derivedValue: "propertyStatus=propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.matchedProperties}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          viewRequired={isEditPermission}
          exportRequired={false}
          sample={false}
          // globalSearch={'Display Name/Email'}
          // type='MatchedProperties'
          // apiUrl={apiCalls.MatchedProperties}
          // exportRequried={true}
          printRequried={false}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.matchedProperties}
          globalSearch={"Search"}
          displayName="Matched Properties"
          type="MatchedProperties"
          routeTo="MatchedProperties"
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.matchedProperties?.toLowerCase()}
          apiUrl={apiCalls.matchedProperties}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          customType={"My Properties"}
          locationState={propertyID}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="matchedProperties"
          apiUrl={apiCalls.matchedProperties}
        />
      ) : null}
    </span>
  );
};

export default MatchedProperties;
