import React, { useEffect, useState } from 'react';
import {Scrollbar} from 'smooth-scrollbar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

import fetch from "../../../config/service";
import apiCalls from '../../../config/apiCalls';

const Sidebar = (props) => {
  
  const [menuList, setMenuList] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({});

  

  useEffect(() => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      setLoginCredentials(loginCredentials);
    }
    getMenuListFromServer()  
    return () => {
      
    };
  }, []);

  const getMenuListFromServer1 = async () => {
   let menuList = [{
  "icon": "employee",
  "displayTitle": "Employees",
  "title": "Employee",
  "route": "/employee"
},{
  "icon": "activities",
  "displayTitle": "Activitiess",
  "title": "Activities",
  "route": "/activities"
},{
  "icon": "email templates",
  "displayTitle": "Email Templatess",
  "title": "Email Templates",
  "route": "/email templates"
},{
  "icon": "roles",
  "displayTitle": "Roless",
  "title": "Roles",
  "route": "/roles"
},{
  "icon": "upload history",
  "displayTitle": "Upload Historys",
  "title": "Upload History",
  "route": "/upload history"
},{
  "icon": "email status",
  "displayTitle": "Email Statuss",
  "title": "Email Status",
  "route": "/email status"
},{
  "icon": "my properties",
  "displayTitle": "My Propertiess",
  "title": "My Properties",
  "route": "/my properties"
},{
  "icon": "clients",
  "displayTitle": "Clientss",
  "title": "Clients",
  "route": "/clients"
},{
  "icon": "search",
  "displayTitle": "Searchs",
  "title": "Search",
  "route": "/search"
},{
  "icon": "saved searches",
  "displayTitle": "Saved Searchess",
  "title": "Saved Searches",
  "route": "/saved searches"
},{
  "icon": "office",
  "displayTitle": "Offices",
  "title": "Office",
  "route": "/office"
},{
  "icon": "agents",
  "displayTitle": "Agentss",
  "title": "Agents",
  "route": "/agents"
},{
  "icon": "tickets",
  "displayTitle": "Ticketss",
  "title": "Tickets",
  "route": "/tickets"
},{
  "icon": "recent matched properties",
  "displayTitle": "Recent Matched Propertiess",
  "title": "Recent Matched Properties",
  "route": "/recent matched properties"
},{
  "icon": "notes",
  "displayTitle": "Notess",
  "title": "Notes",
  "route": "/notes"
},{
  "icon": "matched properties",
  "displayTitle": "Matched Propertiess",
  "title": "Matched Properties",
  "route": "/matched properties"
},{
  "icon": "reminders",
  "displayTitle": "Reminderss",
  "title": "Reminders",
  "route": "/reminders"
}];
  }

  const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }

  const getMenuListFromServer = async () => {    
    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `menus?filter=${JSON.stringify(filterCriteria)}`;
      return fetch('GET', url)
        .then(async (res) => {
          if (res && res.menulists && res.menulists.length > 0) {
            let menuList = res.menulists;
            let rolePermissions = localStorage.getItem("rolePermissions");
            rolePermissions = JSON.parse(rolePermissions);
            if (rolePermissions) {
              let neWmenuList = [];
              let keys = Object.keys(rolePermissions);
              if (menuList) {
                menuList.forEach((item) => {
                  if (item.submenus && item.submenus.length > 0) {
                    let newSubmenus = [];
                    item.submenus.map(sitem => {
                      keys.forEach(element => {
                        if (camelizedString(sitem.displayTitle) === camelizedString(element)) {
                          if (rolePermissions[element].type !== "NoView") {
                            newSubmenus.push(sitem);
                          }
                        }
                      });
                    })
                    if (newSubmenus && newSubmenus.length > 0) {
                      item.submenus = newSubmenus;
                      neWmenuList.push(item);
                    }
                  } else {
                    keys.forEach(element => {
                      if (camelizedString(item.displayTitle) === camelizedString(element)) {
                        if (rolePermissions[element].type !== "NoView") {
                          neWmenuList.push(item);
                        }
                      }
                    });
                  }
                });
              }
              
              setMenuList(neWmenuList);
            }
          }
        })

    }
  }

  
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={menuList}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={menuList}
            />
          </div>
        </Scrollbar>
      </div>
    );
  };


Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;