import React from "react";
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import DoTextareaField from "../../Form/Fields/DoTextareaField";
import fetchMethodRequest from "../../../config/service";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddNotesModal = ({ type, rowData, closeAddNotesModal, contextTypeAction, getActivities }) => {

    const modalHeader = type === "Clients" ? "Notes" : "Comments";

    let {
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch
    } = useForm();

    const notesValue = watch("notes");  // Watch the notes field value

    const submit = (data) => {
        let body = {
            "notes": data.notes,
            "contextType": contextTypeAction,
            "contextId": rowData._id
        };

        return fetchMethodRequest("POST", "notes", body)
            .then(async (response) => {
                let sessionexpired = localStorage.getItem('sessionexpired');
                if (sessionexpired === "true") {
                    setSessionExpiryModal(true);
                }
                if (response && response.respCode) {
                    showToasterMessage(response.responseMessage, 'success');
                    setValue("notes", '');
                    getActivities(rowData._id);
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    };

    return (
        <div className="form-container">
            {/* Header */}
            <div className='d-flex justify-content-between align-items-center pb-3'>
                <h4 className='themeColorText'><b>{"Comments"}</b></h4>
                <FontAwesomeIcon
                    icon='times'
                    onClick={closeAddNotesModal}
                    style={{ cursor: 'pointer', fontSize: '18px' }}
                />
            </div>

            {/* Form */}
            <div className="form-row">
                <form onSubmit={handleSubmit(submit)} className="form-col-12">
                    <div className='form-row align-items-center'>
                        {/* Input field */}
                        <div className='form-col-10'>
                            <Controller
                                name={"notes"}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoTextareaField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        placeholder={`Add ${modalHeader}`}
                                    />
                                )}
                            />
                        </div>

                        {/* Send Button */}
                        <div className='form-col-2'>
                            <Button
                                type='submit'
                                size='sm'
                                className='p-1 rounded-circle'
                                disabled={!notesValue}  // Disable if notes is empty
                            >
                                <FontAwesomeIcon
                                    icon='paper-plane'
                                    title='Send'
                                    style={{ width: '15px', margin: 8 }}
                                />
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddNotesModal;
